/* Base styles */
body {
  background: #f5f5f5;
  color: #333;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Main container styles */
.app-container {
  background: #f5f5f5;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  min-height: calc(100vh - 40px);
  /*box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);*/
}

.App {
  text-align: center;
  background: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navbar Styles */
.navbar {
  background: #f5f5f5;
  color: #333;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px; /* Fixed height for consistent vertical centering */
  position: relative; /* Added for positioning the mobile menu */
}

.navbar-brand h1 {
  color: #333;
  margin: 0;
}

.navbar-brand img {
  max-height: 40px; /* Adjust height as needed */
  width: auto; /* Maintain aspect ratio */
  display: block; /* Ensures proper vertical alignment */
}


.navbar-buttons {
  display: flex;
  gap: 2rem;  /* Increased gap between buttons */
}

.navbar-links {
  display: flex;
  gap: 2rem;
}

.navbar-links a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

/* New navbar button styles */
.navbar .demo-button {
  background: transparent;
  border: 2px solid transparent;
  color: #333;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

/* Remove the underline hover effect */
.navbar .demo-button::after {
  content: none;
}

.navbar .demo-button:hover {
  background-color: rgba(25, 118, 210, 0.1);
  color: #1976D2;
  border: 2px solid #1976D2;
}

/* Dashboard button specific styling */
.navbar .dashboard-button {
  background-color: rgba(25, 118, 210, 0.1);
  color: #1976D2;
  border: 2px solid #1976D2;
}

.navbar .dashboard-button:hover {
  background-color: #1976D2;
  color: white;
  border: 2px solid #1976D2;
}

/* Keep the original demo-button style for other buttons */
.demo-hero-button {
  background: #1976D2;
  color: white;
  padding: 0.75rem 1.75rem;
  font-size: 1.1rem;
  font-weight: 600;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.demo-hero-button:hover {
  /*background: #0c64bd;*/
  transform: translateY(-2px);
}

/* Hero Section */
.hero-section {
  padding: 2rem 6rem;
  margin-top: 1rem;
  display: flex;
  align-items: left;
  justify-content: left;
  background: transparent;
  position: relative;
  min-height: 70vh;
  gap: 4rem;
}

.hero-section .hero-avatar {
  margin-bottom: 15rem;
}

.hero-content {
  flex: 1;
  max-width: 600px;
  margin-right: 14rem;
  margin-left: 0rem;
  /*margin: 0 auto;*/
}

.hero-content h1 {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.1;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  color: #333;
  text-align: left;
}

.hero-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #555;
  margin-bottom: 2rem;
  max-width: 900px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.hero-message {
  font-size: 1.25rem;
  color: #1976D2;
  margin-bottom: 0rem;
  min-height: 30px;
  position: relative;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1.2em;
  background-color: #1976D2;
  animation: blink 1s infinite;
  margin-left: 2px;
  vertical-align: middle;
  position: relative;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.demo-button {
  background-color: #1976D2;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-start;
  transition: all 0.3s ease;
}

.demo-button:hover {
  background-color: #1565C0;
  transform: translateY(-2px);
}

.spline-scene {
  width: 100%;
  height: 100%;
}

/* Utility classes for positioning */
.pointer-events-none {
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.rounded-full {
  border-radius: 9999px;
}

.blur-xl {
  filter: blur(24px);
}

.transition-opacity {
  transition-property: opacity;
}

.duration-200 {
  transition-duration: 200ms;
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.from-white\/20 {
  --tw-gradient-from: rgba(255, 255, 255, 0.2);
  --tw-gradient-to: rgba(255, 255, 255, 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-white\/10 {
  --tw-gradient-to: rgba(255, 255, 255, 0);
  --tw-gradient-stops: var(--tw-gradient-from), rgba(255, 255, 255, 0.1), var(--tw-gradient-to);
}

.to-transparent {
  --tw-gradient-to: transparent;
}

.bg-\[radial-gradient\(circle_at_center\,var\(--tw-gradient-stops\)\)\] {
  background-image: radial-gradient(circle at center, var(--tw-gradient-stops));
}

.hero-avatar {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
}

.hero-avatar img {
  width: 20rem;
  height: auto;
  object-fit: contain;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 1200px) {
  .hero-section {
    padding: 2rem 4rem;
    gap: 2rem;
  }
  
  .hero-content h1 {
    font-size: 3.5rem;
  }
  
  .hero-avatar img {
    width: 18rem;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 2rem;
    flex-direction: column;
    gap: 1rem;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-avatar img {
    width: 14rem;
  }

  .card-content {
    flex-direction: column;
  }

  .card-left, .card-right {
    flex: none;
  }

  .card-right {
    min-height: 300px;
  }

  .title {
    font-size: 2rem;
  }

  .hero-background {
    width: 100%;
    height: 100%;
    bottom: 0;
    position: absolute;
    overflow: visible; /* Allow the image to overflow the container */
  }
  
  .hero-background-image {
    width: 130%; /* Even wider */
    height: auto;
    transform: scaleX(-1) scale(1.4) translateY(0) translateX(-10%); /* Larger scale and shifted more */
    object-fit: cover;
    max-height: none;
    position: absolute;
    bottom: 50px; /* The exact positioning you want */
    right: -40px; /* The exact positioning you want */
  }
}

/* Features Section */
.features {
  background: transparent;
  padding: 4rem 2rem;
}

.features h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333;
}

.dora-features-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1), 
              box-shadow 0.6s cubic-bezier(0.22, 1, 0.36, 1), 
              border-color 0.6s ease,
              background-color 0.6s ease;
  min-width: 0;
  will-change: transform, box-shadow;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.feature-card:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.03);
  background-color: rgba(255, 255, 255, 1);
}

.feature-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-content {
  padding: 1.25rem;
  background: white;
}

.feature-content h3 {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: #333;
}

.feature-content p {
  color: #555;
  line-height: 1.5;
  font-size: 0.95rem;
  margin: 0;
}

@media (max-width: 1200px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }

  .dora-features-grid {
    grid-template-columns: 1fr;
  }
  
  .feature-card {
    max-width: 400px;
    margin: 0 auto;
  }
}

/* Demo Section */
.demo-section {
  padding: 3rem 2rem;
  background: transparent;
}

.demo-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
}

.demo-header .gradient-text {
  padding-left: 1rem;
  background: linear-gradient(to right, #40F8FF, #9580FF);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.demo-header h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.demo-header p {
  font-size: 1.2rem;
  line-height: 1.5;
  opacity: 0.9;
}

.demo-form {
  max-width: 1200px;
  display: block;
  margin: 0 auto;
  background: transparent;
  border-radius: 12px;
  padding: 2rem 4rem;
}

.form-field {
  margin-bottom: 1.5rem;
  margin-right: 1rem;
  flex: 1;
  min-width: 0;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.form-field label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-field input {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
}

.form-field input::placeholder {
  color: #999;
}

.demo-submit {
  width: auto;
  padding: 0.75rem 2rem;
  margin: 1rem auto 0;
  display: block;
}

@media (max-width: 768px) {
  .demo-form {
    padding: 2rem;
  }
  
  .form-row {
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Reduce spacing between sections on mobile */
  .demo-section {
    padding-bottom: 1rem;
  }
  
  /* Add mobile-specific padding for FAQ section */
  .faq {
    padding: 0 1.5rem;
    margin-top: 1rem;
  }
  
  .faq h2 {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  
  .faq-list {
    width: 100%;
  }
  
  /* Improved spacing between question text and icon on mobile */
  .faq-icon {
    margin-left: 1.5rem; /* Increase spacing between text and icon on mobile */
  }
}

/* FAQ Section */
.faq {
  background: transparent;
  color: #333;
  margin-bottom: 6rem; /* Added increased margin to create more space between FAQs and footer */
  padding: 0 2rem; /* Add padding on the sides */
}

.faq h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333;
  padding-left: 0;
}

.faq-list {
  max-width: 1200px;
  margin: 0 auto;
}

.faq-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.faq-question {
  width: 100%;
  text-align: left;
  padding: 1.5rem 0;
  background: none;
  border: none;
  font-size: 1.25rem;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
  position: relative; /* Add position relative for child positioning */
}

.faq-question:hover {
  color: #0056b3;
}

.faq-icon {
  font-size: 1.5rem;
  font-weight: 300;
  color: #666;
  flex-shrink: 0; /* Prevent the icon from shrinking */
  margin-left: 1rem; /* Add space between question text and icon */
}

.faq-answer {
  padding: 0 0 1.5rem;
  color: #555;
  line-height: 1.6;
  font-size: 1.1rem;
}

.faq-answer p {
  margin-bottom: 1rem;
}

.faq-answer p:last-child {
  margin-bottom: 0;
}

/* Footer */
.footer {
  background: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #333;
  padding: 4rem 2rem 2rem;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.footer-section h4 {
  margin-bottom: 1rem;
}

.footer-section a {
  display: block;
  color: #555;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-bottom {
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid #ddd;
}

/* Trusted By Styles */
.trusted-by {
  padding: 4rem 2rem;
  background: white;
  text-align: center;
}

.logo-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.logo-grid img {
  height: 40px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.logo-grid img:hover {
  opacity: 1;
}

.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: rgba(25, 118, 210, 0.9);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1000;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.scroll-to-top:hover {
  background: rgba(25, 118, 210, 1);
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .scroll-to-top {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

/* Video section styles */
.video-container {
  background: transparent;
}

.video-header {
  text-align: center;
}

.video-header h2 {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.2;
  color: #333;
  margin-bottom: 2rem;
}

.gradient-text {
  padding-left: 1rem;
  background: linear-gradient(to right, #40F8FF, #9580FF);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

/* Video player with thumbnail styles */
.video-player-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.video-player-container video {
  width: 100%;
  display: block;
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.video-thumbnail:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.play-button {
  position: relative;
  z-index: 2;
  color: white;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.4));
  opacity: 0.9;
  transition: transform 0.2s ease, opacity 0.2s ease;
  background: rgba(25, 118, 210, 0.8);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-thumbnail:hover .play-button {
  transform: scale(1.1);
  opacity: 1;
  background: rgba(25, 118, 210, 0.95);
}

/* Keep your existing video-section styles */
.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 400px;
  margin: 0 auto;
}

.video-section video {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .video-container {
    padding: 1rem 0;
  }
  
  .video-header {
    padding: 0 2rem;
    margin-bottom: 1rem;
  }

  .video-header h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .video-section {
    min-height: auto;
    margin-bottom: 1rem;
  }
  
  /* Make sure the mobile video container stays the same width */
  .video-player-container {
    max-width: 800px;
  }
  
  .play-button {
    width: 60px;
    height: 60px;
  }
  
  .play-button svg {
    width: 48px;
    height: 48px;
  }
  
  .faq-question {
    padding-right: 1rem; /* Add right padding for the question text */
  }
}

/* Animation styles */
.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  will-change: opacity, transform;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

/* Add different delays for children */
.fade-in-section .feature-card:nth-child(1) { transition-delay: 0.1s; }
.fade-in-section .feature-card:nth-child(2) { transition-delay: 0.2s; }
.fade-in-section .feature-card:nth-child(3) { transition-delay: 0.3s; }
.fade-in-section .feature-card:nth-child(4) { transition-delay: 0.4s; }

.card {
  width: 100%;
  min-height: 500px;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.card-content {
  display: flex;
  flex-direction: row;
  height: 500px;
}

.card-left {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  position: relative;
}

.card-right {
  flex: 1;
  position: relative;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  background: linear-gradient(to bottom, #333, #555);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: left;
}

.description {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #555;
  max-width: 80%;
  text-align: left;
}

/* Hero Card Component styles */
.hero-card {
  width: 100%;
  min-height: 500px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.hero-background {
  position: absolute;
  top: auto;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 82%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

/* Desktop hero background image style */
.hero-background-image {
  width: auto;
  height: 100%;
  object-fit: contain;
  transform: scaleX(-1) translateY(0);
  position: absolute;
  bottom: 0;
  right: 0;
}

.hero-content-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 500px;
  position: relative;
  z-index: 2;
}

.hero-left-content {
  flex: 1;
  padding: 2rem;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-right-content {
  flex: 1;
  position: relative;
  display: flex;
  align-items: flex-end; /* Align content to bottom */
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1rem;
  background: linear-gradient(to bottom, #333, #555);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: left;
  line-height: 1.1;
}

.hero-title div {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.hero-description {
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: #555;
  max-width: 80%;
  text-align: left;
}

.hero-buttons-container {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}

.spotlight-position {
  top: -10rem;
  left: 15rem;
}

.spline-scene {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .hero-card {
    min-height: 750px; /* Increased from 700px to ensure enough space for the larger image */
    display: flex;
    flex-direction: column;
  }

  .hero-content-container {
    flex-direction: column;
    height: auto;
    flex: 1;
    display: flex;
  }

  .hero-left-content {
    padding: 1.5rem;
    flex: none;
    min-height: 0;
  }

  .hero-right-content {
    flex: 1;
    min-height: 300px;
    position: relative;
    width: 100%;
    margin-top: auto; /* Push to bottom */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align to bottom */
  }

  .hero-background {
    width: 100%;
    height: 100%;
    bottom: 0;
    position: absolute;
    overflow: visible; /* Allow the image to overflow the container */
  }
  
  .hero-background-image {
    width: 130%; /* Even wider */
    height: auto;
    transform: scaleX(-1) scale(1.4) translateY(0) translateX(-10%); /* Larger scale and shifted more */
    object-fit: cover;
    max-height: none;
    position: absolute;
    bottom: 50px; /* More space to eliminate gap */
    right: -40px; /* More space to eliminate gap */
  }

  .spotlight-position {
    top: -5rem;
    left: 0;
  }
  
  .hero-title {
    font-size: 2.3rem;
    word-wrap: break-word;
    padding-right: 0.5rem;
  }
  
  .hero-message {
    font-size: 1rem;
  }
  
  .hero-description {
    font-size: 1rem;
    max-width: 100%;
    margin-bottom: 1rem; /* Reduce space */
  }
  
  .hero-buttons-container {
    margin-top: 1rem; /* Reduce space */
  }
  
  .spline-scene {
    width: 100%;
    height: 300px; /* Fixed height */
    position: relative;
    bottom: 0;
  }
}

/* Spotlight styles */
.spotlight {
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.03), transparent 70%);
  filter: blur(20px);
  transition: opacity 0.2s;
}

.spotlight-visible {
  opacity: 1;
}

.spotlight-hidden {
  opacity: 0;
}

.line-spacer {
  display: block;
  height: 30px;
  width: 100%;
  margin: 10px 0;
}

/* Adjust SVG text sizing */
.inline-svg {
  height: auto;
  width: 100%;
  /* vertical-align: middle; */
  display: block;
  margin: 0;
  padding: 0;
}

/* Prevent line break in title */
.no-wrap {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .svg-text {
    font-size: 42px !important; /* Override the inline font size for mobile */
  }
  
  /* Specific style for the Estate Agencies text to make it bigger */
  svg:nth-of-type(2) .svg-text {
    font-size: 58px !important; /* Make Estate Agencies text bigger */
  }
  
  /* Specific style for the AI Voice Agent text to make it bigger */
  svg:nth-of-type(1) .svg-text {
    font-size: 50px !important; /* Make AI Voice Agent text bigger */
  }
  
  /* Add space between AI Voice Agent and 'designed for' text */
  .hero-title svg:nth-of-type(1) {
    margin-bottom: -10px !important; /* Add extra spacing after AI Voice Agent text */
  }
  
  /* Control spacing between 'designed for' and Estate Agencies text */
  .hero-title div:nth-of-type(2) {
    margin-bottom: 3px !important; /* Add space after 'designed for' text */
  }
}

/* Feature card image inversion styles */
/* .feature-card:nth-child(2) .feature-image img, */
/* .feature-card:nth-child(4) .feature-image img {
  transform: scaleX(-1);
} */

/* Custom positioning for Complete Customisability card image */
.feature-card:nth-child(2) .feature-image img {
  object-position: center 40%;  /* Move image down to show all icons */
}

/* Mobile Menu Styles */
.mobile-menu-button {
  background: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  z-index: 1001;
  margin-right: -8px;
}

.menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.mobile-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  z-index: 1000;
  border-radius: 0 0 12px 12px;
}

.mobile-menu.open {
  max-height: 300px;
  padding: 0.5rem 0;
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from { transform: translateY(-10px); opacity: 0.8; }
  to { transform: translateY(0); opacity: 1; }
}

.mobile-menu-item {
  background: transparent;
  border: none;
  color: #333;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  transition: background-color 0.2s ease, color 0.2s ease;
}

.mobile-menu-item:last-child {
  border-bottom: none;
}

.mobile-menu-item:hover {
  background: rgba(25, 118, 210, 0.04);
  color: #1976D2;
}

.mobile-menu-item.dashboard-button {
  color: #1976D2;
  font-weight: 700;
  background-color: rgba(25, 118, 210, 0.05);
  border: 2px solid #1976D2;
  border-radius: 6px;
  margin: 0.5rem 1rem;
  width: calc(100% - 2rem);
  box-sizing: border-box;
  text-align: center;
}

.mobile-menu-item.dashboard-button:hover {
  background-color: rgba(25, 118, 210, 0.1);
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .navbar {
    padding: 0.75rem 1rem;
  }
  
  .navbar-brand img {
    max-height: 32px;
  }
}

/* New Features Section */
.new-features {
  background: transparent;
  max-width: 1200px;
  margin: 0 auto;
}

.features-header {
  text-align: center;
  margin-bottom: 4rem;
}

.features-label {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1976D2;
  margin-bottom: 1rem;
  letter-spacing: 0.1em;
}

.features-title {
  margin-bottom: 2rem;
}

.features-title h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #333;
  margin: 0;
  line-height: 1.2;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.dora-features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.feature-item {
  text-align: center;
  padding: 1.5rem;
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-icon {
  width: 64px;
  height: 64px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feature-item h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.feature-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

@media (max-width: 1024px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1024px) {
  .dora-features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .features-grid {
    grid-template-columns: 1fr;
  }
  
  .features-title h2 {
    font-size: 2rem;
  }
  
  .new-features {
    padding: 4rem 1.5rem;
  }
}

/* Dora Features Section (Why Choose Doora) */
.dora-features {
  background: transparent;
  padding: 4rem 2rem;
}

.dora-features h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333;
}

.dora-features-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.dora-feature-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1), 
              box-shadow 0.6s cubic-bezier(0.22, 1, 0.36, 1), 
              border-color 0.6s ease,
              background-color 0.6s ease;
  min-width: 0;
  will-change: transform, box-shadow;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.dora-feature-card:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.03);
  background-color: rgba(255, 255, 255, 1);
}

.dora-feature-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.dora-feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dora-feature-content {
  padding: 1.25rem;
  background: white;
}

.dora-feature-content h3 {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: #333;
}

.dora-feature-content p {
  color: #555;
  line-height: 1.5;
  font-size: 0.95rem;
  margin: 0;
}

@media (max-width: 1200px) {
  .dora-features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .dora-features-grid {
    grid-template-columns: 1fr;
  }
  
  .dora-feature-card {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .faq-question {
    padding-right: 1rem; /* Add right padding for the question text */
  }
  
  /* Improved spacing between question text and icon on mobile */
  .faq-icon {
    margin-left: 1.5rem; /* Increase spacing between text and icon on mobile */
  }
}
